import { Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';

import PageNotFound from './component/PageNotFound';
import LoginPage from './component/LoginPage';
import Dashboard from './component/Dashboard';
import './App.css';

// CRM - START
import SubscribersList from './component/Crm/Subscriber/List';
import ContactList from './component/Crm/Contact/List';
import DealersList from './component/Crm/Dealer/List';
import WarrantyRegistration from './component/Crm/WarrantyRegistration/List';
import ReviewAndRating from './component/Crm/review-and-rating';
// CRM - END

// ERP - START
import AttributeSetList from './component/Erp/AttributeSet/List';
import AttributeSetAdd from './component/Erp/AttributeSet/Add';
import AttributeSetEdit from './component/Erp/AttributeSet/Edit';

import AttributeList from './component/Erp/Attribute/List';
import AttributeAdd from './component/Erp/Attribute/Add';
import AttributeEdit from './component/Erp/Attribute/Edit';

import TagList from './component/Erp/Tag/List';
import TagAdd from './component/Erp/Tag/Add';
import TagEdit from './component/Erp/Tag/Edit';

import BrandList from './component/Erp/Brand/List';
import BrandAdd from './component/Erp/Brand/Add';
import BrandEdit from './component/Erp/Brand/Edit';

import CategoryList from './component/Erp/Category/List';
import CategoryAdd from './component/Erp/Category/Add';
import CategoryEdit from './component/Erp/Category/Edit';
import CategoryMappingWithAttributeSet from './component/Erp/Category/MappingWithAttributeSet';

import ProductList from './component/Erp/Product/List';
import ProductAdd from './component/Erp/Product/Add';
import ProductEdit from './component/Erp/Product/Edit';

import OrdersList from './component/Erp/Orders/List';

import CartList from './component/Erp/Carts/List';
// ERP - END

// WEB - START
import MenuList from './component/Web/menu.list';
import MenuAdd from './component/Web/menu.add';

import BlogCategoryList from './component/Web/blogCategory.list';
import BlogCategoryAdd from './component/Web/blogCategory.add';

import BlogList from './component/Web/blog.list';
import BlogAdd from './component/Web/blog.add';

import PageList from './component/Web/page.list';
import PageAdd from './component/Web/page.add';
import StoreLocator from './component/Crm/StoreLocator/List';
import Analytics from './component/Analytics';
import CustomerList from './component/Crm/Customer/List';
import AccessManagementModule from './component/AccessManagement/Modules/AccessManagementModule';
import AccessManagementRole from './component/AccessManagement/Roles/AccessManagementRole';
import AccessManagementUser from './component/AccessManagement/Users';
import { getModules } from './api/auth';
import { useBetween } from 'use-between';
import useShareableState from './Common/useShareableState'
import WebsiteAnalyticsPage from './component/WebsiteAnalyticsPage';
import TrafficAquisitionAnalyticsPage from './component/TrafficAquisitionAnalyticsPage';
import EcommerceAnalyticsPage from './component/EcommerceAnalyticsPage';

// WEB - END

function App() {
  const { setModulesList } = useBetween(useShareableState);

  const getModuleDetails = async () => {
    let res = await getModules();
    if (res?.status) {
      setModulesList(res?.payload);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getModuleDetails();
    }
  }, []);

  return (
    <div className='App'>
      <>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          {/* <Route path='/analytics' element={<Analytics />} /> */}
          <Route path='/analytics/website' element={<WebsiteAnalyticsPage />} />
          <Route path='/analytics/traffic-aquisition' element={<TrafficAquisitionAnalyticsPage />} />
          <Route path='/analytics/ecommerce' element={<EcommerceAnalyticsPage />} />

          {/* CRM - START */}
          <Route path='/subscribers/' element={<SubscribersList />} />
          <Route path='/contact/' element={<ContactList />} />
          <Route path='/customer' element={<CustomerList />} />
          <Route path='/dealer/requested' element={<DealersList status={'requested'} />} />
          <Route path='/dealer/approved' element={<DealersList status={'authorized'} />} />
          <Route path='/dealer/cancelled' element={<DealersList status={'unauthorized' }/>} />
          <Route path='/warranty-registration/' element={<WarrantyRegistration />} />
          <Route path='/store-locator/' element={<StoreLocator />} />
          <Route path='/review-rating/' element={<ReviewAndRating />} />
          {/* CRM - END */}

          {/* ERP - START */}
          <Route path='/attribute-set/list' element={<AttributeSetList />} />
          <Route path='/attribute-set/add' element={<AttributeSetAdd />} />
          <Route path='/attribute-set/edit' element={<AttributeSetEdit />} />

          <Route path='/attribute/list' element={<AttributeList />} />
          <Route path='/attribute/add' element={<AttributeAdd />} />
          <Route path='/attribute/edit' element={<AttributeEdit />} />

          <Route path='/tag/list' element={<TagList />} />
          <Route path='/tag/add' element={<TagAdd />} />
          <Route path='/tag/edit' element={<TagEdit />} />

          <Route path='/brand/list' element={<BrandList />} />
          <Route path='/brand/add' element={<BrandAdd />} />
          <Route path='/brand/edit' element={<BrandEdit />} />

          <Route path='/category/list' element={<CategoryList />} />
          <Route path='/category/add' element={<CategoryAdd />} />
          <Route path='/category/edit' element={<CategoryEdit />} />
          <Route path='/category/attribute-set/mapping' element={<CategoryMappingWithAttributeSet />} />

          <Route path='/product/list' element={<ProductList />} />
          <Route path='/product/add' element={<ProductAdd />} />
          <Route path='/product/edit' element={<ProductEdit />} />

          <Route path='/orders/list' element={<OrdersList />} />
          <Route path='/orders/placed' element={<OrdersList orderStatus='placed' />} />
          <Route path='/orders/confirmed' element={<OrdersList orderStatus='confirmed' />} />
          <Route path='/orders/dispatched' element={<OrdersList orderStatus='dispatched' />} />
          <Route path='/orders/delivered' element={<OrdersList orderStatus='delivered' />} />
          <Route path='/orders/cancelled' element={<OrdersList orderStatus='cancelled' />} />
          <Route path='/orders/failed' element={<OrdersList orderStatus='failed' />} />

          <Route path='/Carts' element={<CartList />} />

          {/* ERP - END */}

          {/* WEB - START */}
          <Route path='/web/menu' element={<MenuList />} />
          <Route path='/web/menu/add' element={<MenuAdd />} />
          <Route path='/web/menu/edit' element={<MenuAdd />} />

          <Route path='/web/blog-category' element={<BlogCategoryList />} />
          <Route path='/web/blog-category/add' element={<BlogCategoryAdd />} />
          <Route path='/web/blog-category/edit' element={<BlogCategoryAdd />} />

          <Route path='/web/blog' element={<BlogList />} />
          <Route path='/web/blog/add' element={<BlogAdd />} />
          <Route path='/web/blog/edit' element={<BlogAdd />} />

          <Route path='/web/page' element={<PageList />} />
          <Route path='/web/page/add' element={<PageAdd />} />
          <Route path='/web/page/edit' element={<PageAdd />} />
          {/* WEB - END */}

          <Route path='/access-management-roles' element={<AccessManagementRole />} />
          <Route path='/access-management-modules' element={<AccessManagementModule />} />
          <Route path='/access-management-users' element={<AccessManagementUser />} />

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </>
    </div>
  );
}

export default App;
