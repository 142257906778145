import React from "react";
import { NavLink } from "react-router-dom";
import { useBetween } from "use-between";
import useShareableState from "../../Common/useShareableState";

export function Sidebar() {
  const pathName = window.location.pathname;
  const { modulesList } = useBetween(useShareableState);
  // const baseURL = window.location.origin; console.log(baseURL);
  // const splittedPathName = pathName.replace(baseURL,'').trim();
  const splittedPathArr = pathName.split("/");
  const newPathArr = splittedPathArr.splice(1);
  const splittedPathName = newPathArr.join("/"); //splittedPathArr[splittedPathArr.length-1];

  let analyticsExpMenusSlug = [
    "analytics/website",
    "analytics/ecommerce",
    "analytics/traffic-aquisition"
  ];
  let crmExpMenusSlug = [
    "contact",
    "leads",
    "subscribers",
    "customer",
    "dealer/requested",
    "dealer/approved",
    "dealer/cancelled",
    "warranty-registration",
    "store-locator",
    "supplier",
    "review-rating",
  ];
  let erpExpMenusSlug = [
    "category/list",
    "category/add",
    "category/edit",
    "category/attribute-set/mapping",
    "attribute-set/list",
    "attribute-set/add",
    "attribute-set/edit",
    "attribute/list",
    "attribute/add",
    "attribute/edit",
    "brand/list",
    "brand/add",
    "brand/edit",
    "tag/list",
    "tag/add",
    "tag/edit",
    "product/list",
    "product/add",
    "product/edit",
    "inventory",
    "orders/placed",
    "orders/confirmed",
    "orders/dispatched",
    "orders/delivered",
    "orders/cancelled",
    "orders/list",
    "packages",
    "carts",
  ];
  let uamExpMenusSlug = [
    "access-management-roles",
    "access-management-modules",
    "access-management-users",
  ];
  let webExpMenusSlug = [
    "web/menu",
    "web/menu/items",
    "web/blog",
    "web/blog/add",
    "web/page",
    "web/page/add",
    "web/blog-category",
    "web/blog-category/add",
    "web/blog-category/edit",
  ];

  const getHrefForModule = (slug) => {
    if (slug === "crm") {
      return "crmSidebarControl";
    } else if (slug === "analytics") {
      return "analyticsSidebarControl";
    } else if (slug === "erp") {
      return "erpSidebarControl";
    } else if (slug === "website-content") {
      return "WEBSidebarControl";
    } else if (slug === "access-management") {
      return "UAMSidebarControl";
    }
  };

  const getSubModules = (slug) => {
    if (slug === "crm") {
      return crmExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    } else if (slug === "analytics") {
      return analyticsExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    } else if (slug === "erp") {
      return erpExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    } else if (slug === "website-content") {
      return webExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    } else if (slug === "access-management") {
      return uamExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    }
  };

  const getShowHideDropdown = (slug) => {
    if (slug === "analytics") {
      return analyticsExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
    } else if (slug === "crm") {
      return crmExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
    } else if (slug === "erp") {
      return erpExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
      } else if (slug === "erp") {
        return erpExpMenusSlug.includes(splittedPathName)
          ? "collapse menu-dropdown show"
          : "collapse menu-dropdown";
    } else if (slug === "website-content") {
      return webExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
    } else if (slug === "access-management") {
      return uamExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
    }
  };
  return (
    <div id="layout-wrapper">
      <div className="app-menu navbar-menu">
        {/* <!-- LOGO --> */}
        <div className="navbar-brand-box">
          {/* <!-- Dark Logo--> */}
          <NavLink to={"/"} className="logo logo-dark">
            <span className="logo-sm">
              <img
                src="/assets/images/logo-big.png"
                alt=""
                style={{ width: "100%", height: "40px" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src="/assets/images/logo-big.png"
                alt=""
                style={{ width: "100%", height: "40px" }}
              />
            </span>
          </NavLink>
          {/* <!-- Light Logo--> */}
          <NavLink to={"/"} className="logo logo-light">
            <span className="logo-sm">
              <img
                src="/assets/images/logo-big.png"
                alt=""
                style={{ width: "100%", height: "40px" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src="/assets/images/logo-big.png"
                alt=""
                style={{ width: "100%", height: "40px" }}
              />
            </span>
          </NavLink>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover "
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <aside className="scrool_s">
          <div className="scrool">
            <ul className="navbar-nav">
              {modulesList?.map((module) => {
                if (module?.child?.length === 0) {
                  return (
                    <li className="nav-item">
                      <NavLink to={module?.path} className="nav-link menu-link">
                        <i className={module?.icon}></i> {module?.name}
                      </NavLink>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item">
                      <a
                        className="nav-link menu-link"
                        href={`#${getHrefForModule(module?.slug)}`}
                        data-bs-toggle="collapse"
                        role="button"
                        aria-controls={getHrefForModule(module?.slug)}
                        aria-expanded={getSubModules(module?.slug)}
                      >
                        <i className={module?.icon}></i> {module?.name}
                      </a>
                      <div
                        className={getShowHideDropdown(module?.slug)}
                        id={getHrefForModule(module?.slug)}
                      >
                        <ul className="nav nav-sm flex-column">
                          {module?.child?.map((item) => {
                            if (item?.child?.length === 0) {
                              return (
                                <li className="nav-item" key={item?.ID}>
                                  <NavLink to={item?.path} className="nav-link">
                                    {item?.name}
                                  </NavLink>
                                </li>
                              );
                            } else {
                              return (
                                <li className="nav-item">
                                  <NavLink to={item.path} className="nav-link">
                                    {item.name}
                                  </NavLink>
                                  <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                      {item?.child?.map((item2) => {
                                        if (item2?.child?.length === 0) {
                                          return (
                                            <NavLink
                                              to={item2?.path}
                                              className="nav-link"
                                            >
                                              {item2?.name}
                                            </NavLink>
                                          );
                                        } else {
                                          return item2?.child?.map((item3) => {
                                            return (
                                              <>
                                                <NavLink
                                                  to={item2.path}
                                                  className="nav-link"
                                                >
                                                  {item2?.name}
                                                </NavLink>
                                                <ul className="nav nav-sm flex-column">
                                                  <li className="nav-item">
                                                    <NavLink
                                                      to={item3?.path}
                                                      className="nav-link"
                                                    >
                                                      {item3?.name}
                                                    </NavLink>
                                                  </li>
                                                </ul>
                                              </>
                                            );
                                          });
                                        }
                                      })}
                                    </li>
                                  </ul>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
            {/* <!-- Sidebar --> */}
          </div>
        </aside>
        <div className="sidebar-background"></div>
      </div>
    </div>
  );
}

export default Sidebar;
