import Swal from 'sweetalert2';

import { deleteAPI, get, putAPI } from '../services/api.services';
import { defaultPage } from '../Common/Constant';

export const list = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = '',
}) => {
  let data = {
    page,
    recPerPage,
    searchKeyword,
  };
  let res = await get('/reviews', data);
  return res;
};

export const statusChange = async (id, payload) => {
  return Swal.fire({
    text: 'Are you sure you want to update?',
    confirmButtonText: 'Yes',
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await putAPI(`/reviews/${id}`, payload);
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};

export const deleteRow = async (id) => {
  return Swal.fire({
    text: 'Are you sure you want to delete?',
    confirmButtonText: 'Yes',
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await deleteAPI(`/reviews/${id}`);
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};
