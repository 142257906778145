import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { deleteRow, list, statusChange } from '../../../api/review-and-rating';
import { defaultPage } from '../../../Common/Constant';
import Pagination from '../../Layout/Pagination';
import Sidebar from '../../Layout/Sidebar';
import Header from '../../Layout/Header';

const ReviewAndRating = () => {
  const [pageInfo, setPagination] = useState({});
  const [lists, setLists] = useState([]);
  const [search, setSearch] = useState('');

  const changePage = (p) => {
    setPagination((prev) => ({ ...prev, current_page: p }));
  };

  const fetchReviewList = useCallback(async () => {
    let res = await list({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: search,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPagination(res.paginationInfo);
    } else {
      setLists([]);
    }
  }, [pageInfo.current_page, search]);

  const updateStatus = async (id, status) => {
    const res = await statusChange(id, { status: status === 1 ? 'unpublish' : 'publish' });

    if (res && res.status) {
      fetchReviewList();
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteRow(id);
    if (res && res.status) {
      fetchReviewList();
    }
  };

  useEffect(() => {
    fetchReviewList();
    document.title = 'Review & Rating - Clarion Audio';
  }, [fetchReviewList]);

  return (
    <>
      <Sidebar />
      <Header />

      <div id='layout-wrapper'>
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              {/* sub header */}
              <div className='row'>
                <div className='col-12'>
                  <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4 className='mb-sm-0'>Review & Rating</h4>
                    <div className='page-title-right'>
                      <ol className='breadcrumb m-0'>
                        <li className='breadcrumb-item'>
                          <Link to='/'>Home</Link>
                        </li>
                        <li className='breadcrumb-item active'>Review & Rating</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              {/* Search */}
              <div
                className='search-orderid'
                style={{
                  background: '#ffffff',
                  marginBottom: '5px',
                  width: '320px',
                }}
              >
                <input
                  type='search'
                  placeholder='Search by product name, review details'
                  className='search-input'
                  style={{ width: '90%' }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <img src='/assets/images/search.png' alt='search' width={18} height={18} style={{ marginTop: '1px' }} />
              </div>

              {/* List */}
              <div className='row mt-2'>
                <div className='col-xl-12 col-lg-12'>
                  <div className='card'>
                    <div className='card-body'>
                      <table className='table table-striped table-hover table_client'>
                        <thead>
                          <tr className='bg-primary' style={{ color: 'white' }}>
                            <th scope='col'>Sr. No.</th>
                            <th scope='col' style={{ textAlign: 'left' }}>
                              Product details
                            </th>
                            <th scope='col' style={{ textAlign: 'left' }}>
                              Review details
                            </th>
                            <th scope='col'>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists.length > 0 ? (
                            lists.map((item) => {
                              return (
                                <tr key={item.ID}>
                                  <td>1</td>
                                  <td className='text-start'>
                                    <div className='d-flex gap-2'>
                                      <div>
                                        <img
                                          src={item.productDetails.image}
                                          alt={item.productDetails.name}
                                          width={80}
                                        />
                                      </div>
                                      <div>
                                        <p className='mb-0'>
                                          <span>Name:</span> <span>{item.productDetails.name}</span>
                                        </p>
                                        <p className='mb-0'>
                                          <span>SKU Code:</span> <span>{item.productDetails.skuCode}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='text-start'>
                                    <div className='d-flex gap-2'>
                                      <div>
                                        <img src={item.reviewDetails.media[0]} alt='' width={80} />
                                      </div>
                                      <div>
                                        <p className='mb-0'>
                                          <span>Name:</span> <span>{item.reviewDetails.name}</span>
                                        </p>
                                        <p className='mb-0'>
                                          <span>Rated:</span> <span>{item.reviewDetails.rating}</span>
                                        </p>
                                        <p className='mb-0'>
                                          <span>Likes:</span> <span>{item.reviewDetails.likes}</span>
                                        </p>
                                        <p className='mb-0'>
                                          <span>Dislikes:</span> <span>{item.reviewDetails.dislikes}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex justify-content-center align-items-center'>
                                      <div className='form-check form-switch text-center'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          role='switch'
                                          id='flexSwitchCheckDefault'
                                          checked={item.status === 1 ? 'checked' : ''}
                                          onChange={() => updateStatus(item.ID, item.status)}
                                        />
                                      </div>
                                      <div className='p-2 font-18' title='Delete Review & Rating'>
                                        <i
                                          className='ri-delete-bin-2-line del pointerC'
                                          onClick={() => handleDelete(item.ID)}
                                        ></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={4}>No Review & Rating found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <Pagination pageInfo={pageInfo} changePage={changePage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewAndRating;
